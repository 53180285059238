import React, { useEffect } from "react";
import { dispatch } from "../redux";

const useTip = slug =>
    useEffect(() => {
        if (!slug) {
            dispatch("tips/all");
        } else {
            dispatch("tips/get-by-slug", decodeURI(slug));
        }
    }, [slug]);

export default useTip;