import React from "react";
import styled from 'styled-components';
import {arno, colors as color} from "../../const";
import { A } from 'hookrouter';


export const arrow =
<svg xmlns="http://www.w3.org/2000/svg" width="6.299" height="9.769" viewBox="0 0 6.299 9.769">
    <path id="Path_39" data-name="Path 39" d="M819.6,1500.276l4.177,4.177-4.177,4.177" transform="translate(-818.892 -1499.569)" fill="none" stroke="#873b07" strokeWidth="2"/>
</svg>
;
export const LinkButton = (link, backgroundColor) => {
    if(link.anchor || link.external || link.blank){
        return (
            <LinkA className={backgroundColor && 'colored'} href={link.url} target={link.blank ? '_blank' : '' }>
                <div>
                    {link.name} <span>{arrow}</span>
                </div>
            </LinkA>
        )
    }
    return (
        <Link className={backgroundColor && 'colored'} href={link.url} target={link.blank ? '_blank' : '' }>
            <div>
            {link.name} <span>{arrow}</span>
            </div>
        </Link>
    )
};

const Link = styled(A)`
    text-decoration: none;
    ${arno(22)}
    color: ${color.red};
    border: 1px solid ${color.darkGrey};
    &.colored{
        background-color: ${color.brown};
    }
    display: inline-flex;
    padding: 5px 25px 5px 20px;
    span{
        height: 100%;
        width: 15px;
        position: relative;
    }    
    svg{
        position: absolute;
        top: 0;
        left: 5px;
        height: 50%;
        transform: translateY(50%);
    }
    &:hover svg{
        transform: translateX(10%) translateY(50%) scale(1.1);
    }

    }
    
`;



const LinkA = styled.a`
    text-decoration: none;
    ${arno(22)}
    color: ${color.red};
    border: 1px solid ${color.darkGrey};
    &.colored{
        background-color: ${color.brown};
    }
    display: inline-flex;
    padding: 5px 25px 5px 20px;
    span{
        height: 100%;
        width: 15px;
        position: relative;
    }    
    svg{
        position: absolute;
        top: 0;
        left: 5px;
        height: 50%;
        transform: translateY(50%);
    }
    &:hover svg{
        transform: translateX(10%) translateY(50%) scale(1.1);
    }

    }
    
`;


