const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'tips/set':
                let data = [];
                action.payload.forEach((tip) => {
                   if(tip.slug) data[tip.slug.current] = tip
                });
            return { ...state, ...data };
        case 'tips/update':
            return { ...state, ...payload };
        case 'tips/clear':
        case 'REDUX/CLEAR':
            return { ...defaultState };
        default:
            return state;
    }
};
