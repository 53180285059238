
import { useEffect } from "react";
import { dispatch } from "../redux";

const useArticle = slug =>
    useEffect(() => {
        if (!slug) {
            dispatch("articles/all");
        } else {
            dispatch("articles/get-by-slug", decodeURI(slug));
        }
    }, [slug]);

export default useArticle;
