import React, { useState, useMemo } from 'react';
import { FilterWrapper, FilterButtonWrapper, FilterButton, Filter, Filters, CheckedWrapper ,circleBorder } from './visuals';
import { arrow } from "../partials/LinkButton";

const FilterDropdown = ({ allergies, allergyFilters, setAllergyFilter }) => {
	const [visible, setVisible] = useState(true);
	const convertedObject = useMemo(() => Object.keys(allergies));
	
	const toggleAllergy = (title) => {
		setAllergyFilter(
			allergyFilters.includes(title) ?
				allergyFilters.filter(allergy => allergy !== title) :
				[...allergyFilters, title]
		);
	}
	
	if(!convertedObject) return null;
	
	return(
		<FilterWrapper>
			<FilterButtonWrapper>
				<FilterButton className={visible && "hidden"}>
					<div onClick={() => setVisible(!visible)}>
						{visible ? "Filtrer på allergier" : "Skjul filter"} {arrow}
					</div>
				</FilterButton>
			</FilterButtonWrapper>
			<Filters className={visible && "hidden"}>
				{convertedObject && (
					<Filter>
						<h3>Fri for</h3>
						<div>
							{convertedObject &&
								convertedObject.map((allergy, i) => {
									return (
										<CheckedWrapper
											onClick={() => toggleAllergy(allergy)}
											key={i}
										>
											{circleBorder(allergyFilters.includes(allergy))}
											<div>{allergy}</div>
										</CheckedWrapper>
									);
								})}
						</div>
					</Filter>
				)}
			</Filters>
		</FilterWrapper>
	);
};

export default FilterDropdown;