import React, { useEffect } from 'react';
import { dispatch } from '../redux';

const useGlobals = () =>
    useEffect(() => {
            dispatch('globals/get');
    });


export default useGlobals;
