import React from "react";
import styled from "styled-components";
import { arno, colors } from "../const";
import { A } from "hookrouter";
import connect from "react-redux/es/connect/connect";
import { useInView } from "react-hook-inview";
import { SanityParser } from "../modules/controllers/SanityParser";
import useGlobals from "../hooks/useGlobals";
import useProduct from "../hooks/useProduct";
import useRecipe from "../hooks/useRecipe";
import useTip from "..//hooks/useTip";
import useArticle from "../hooks/useArticle";
import usePage from "../hooks/usePage";
import { Image } from "../modules/partials/Image";

const ItemWrapper = (itemList, type) => {
  return (
    <ProductsWrapper>
      {Object.keys(itemList).map((key) => {
        let product = itemList[key];
        const parsed = SanityParser({
          _type: type,
          ...product,
        });

        let baseUrl;
        switch (type) {
          case "product":
            baseUrl = "/./produkter/";
            break;
          case "recipe":
            baseUrl = "/./oppskrifter/";
            break;
          case "tip":
            baseUrl = "/./tips/";
            break;
          default:
            baseUrl = "/";
        }
        if (baseUrl === parsed.link) {
          baseUrl = "";
        }

        console.log("PRODUCT: ", parsed.image_small);
        return (
          <Product href={baseUrl + parsed.link} key={product._id}>
            <ImageWrapper>
              {parsed.image_small && (
                <Image
                  alt={type}
                  src={parsed.image_small}
                  source={parsed.imageSmallObj}
                  width={400}
                />
              )}
            </ImageWrapper>
            <ProductInfo>
              {type === "recipe" && type === "product" && (
                <div>{parsed.category}</div>
              )}
              {type === "article" && <div>Artikkel</div>}
              {type === "recipe" && (
                <>
                  <div>{parsed.title}</div>
                  <div />
                  <div>{parsed.category}</div>
                </>
              )}
              {type === "tip" && (
                <>
                  <div>{parsed.title}</div>
                  <div />
                  <div>{parsed.category}</div>
                </>
              )}
              {type === "product" && (
                <>
                  <div>{parsed.title}</div>
                  <div>{parsed.weight}</div>
                  <div>{parsed.category}</div>
                </>
              )}
              {type === "article" && (
                <>
                  <div>{parsed.title}</div>
                </>
              )}
              {type === "page" && (
                <>
                  <div>
                    {parsed.title === "Home " ? "Forside" : parsed.title}
                  </div>
                </>
              )}
            </ProductInfo>
          </Product>
        );
      })}
    </ProductsWrapper>
  );
};

const SearchPage = ({
  slug,
  type,
  text,
  title,
  pageState,
  articleState,
  productState,
  recipeState,
  tipState,
  backgroundColor,
}) => {
  useGlobals();
  useProduct();
  useArticle();
  useRecipe();
  useTip();
  usePage();

  const [ref, isVisible] = useInView({ unobserveOnEnter: true });

  const decodedSlug = decodeURI(slug);

  let filteredProducts = [];
  productState &&
    Object.keys(productState).map((key) => {
      const product = productState[key];

      let categoryTest = product.category.title
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      let titleTest = product.title
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      if (titleTest || categoryTest) {
        filteredProducts = [...filteredProducts, { ...product }];
      }
    });

  let filteredRecipes = [];
  recipeState &&
    Object.keys(recipeState).map((key) => {
      const recipe = recipeState[key];
      let titleTest = recipe.title
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      let categoryTest = recipe.category.title
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      if (titleTest || categoryTest) {
        filteredRecipes = [...filteredRecipes, { ...recipe }];
      }
    });

  let filteredTips = [];
  tipState &&
    Object.keys(tipState).map((key) => {
      const tip = tipState[key];
      let titleTest = tip.title
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      let categoryTest = tip.category.title
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      if (titleTest || categoryTest) {
        filteredTips = [...filteredTips, { ...tip }];
      }
    });

  let articleSearchResults = [];
  articleState &&
    Object.keys(articleState).forEach((key) => {
      const article = articleState[key];
      let textCheck = article.searchable
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      if (textCheck) {
        articleSearchResults = [...articleSearchResults, { ...article }];
      }
    });
  let pageSearchResults = [];
  pageState &&
    Object.keys(pageState).forEach((key) => {
      const page = pageState[key];
      if (!page) return;
      if (!page.searchable) return;
      let textCheck = page.searchable
        .toLowerCase()
        .includes(decodedSlug.toLowerCase());
      if (textCheck) {
        pageSearchResults = [...pageSearchResults, { ...page }];
      }
    });
  const searching =
    Object.keys(recipeState).length <= 0 &&
    Object.keys(tipState).length <= 0 &&
    Object.keys(articleState).length <= 0 &&
    Object.keys(productState).length <= 0 &&
    Object.keys(pageState).length <= 0;

  const noHits =
    filteredProducts.length < 1 &&
    filteredRecipes < 1 &&
    filteredTips < 1 &&
    articleSearchResults < 1 &&
    pageSearchResults < 1;

  return (
    <Container
      backgroundColor={backgroundColor}
      className={"module module-event-list " + (isVisible ? "in-view" : "")}
      ref={ref}
    >
      <ContentWrapper>
        {searching && (
          <CategoryWrapper>
            <Category>
              <h2>Searching...</h2>
            </Category>
          </CategoryWrapper>
        )}
        {!searching && noHits && Object.keys(recipeState).length > 0 && (
          <CategoryWrapper>
            <Category>
              <h2>Ingen treff</h2>
            </Category>
          </CategoryWrapper>
        )}
        {!searching && filteredProducts.length > 0 && (
          <CategoryWrapper type={"product"}>
            <Category>
              <h2>Produkter</h2>
              {ItemWrapper(filteredProducts, "product")}
            </Category>
          </CategoryWrapper>
        )}
        {!searching && filteredRecipes.length > 0 && (
          <CategoryWrapper type={"recipe"}>
            <Category>
              <h2>Oppskrifter</h2>
              {ItemWrapper(filteredRecipes, "recipe")}
            </Category>
          </CategoryWrapper>
        )}
        {!searching && filteredTips.length > 0 && (
          <CategoryWrapper type={"tip"}>
            <Category>
              <h2>Tips</h2>
              {ItemWrapper(filteredTips, "tip")}
            </Category>
          </CategoryWrapper>
        )}

        {!searching && articleSearchResults.length > 0 && (
          <CategoryWrapper>
            <Category>
              <h2>Artikler</h2>
              {ItemWrapper(articleSearchResults, "article")}
            </Category>
          </CategoryWrapper>
        )}
        {!searching && pageSearchResults.length > 0 && (
          <CategoryWrapper>
            <Category>
              <h2>Sider</h2>
              {ItemWrapper(pageSearchResults, "page")}
            </Category>
          </CategoryWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};
const mapStateToProps = (state, ownProps) => {
  return Object.assign(
    {},
    {
      pageState: state.pages,
      recipeState: state.recipe,
      tipState: state.tip,
      productState: state.product,
      articleState: state.article,
    },
    ownProps
  );
};
export default connect(mapStateToProps, null)(SearchPage);

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.brown};` : ""}
  padding-top: 50px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CategoryWrapper = styled.div`
  &:nth-last-child(2n) {
    ${(props) =>
      (props.type === "product" || props.type === "recipe") &&
      `background-color: ${colors.brown};`}
  }
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Category = styled.div`
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  width: 100%;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 55px;
    margin-right: 55px;
  }
  h2 {
    ${arno(60)}
    margin-bottom: 20px;
  }
  p {
    ${arno(18)}
    max-width: 700px;
    margin-top: 0;
    margin-bottom: 40px;
  }
`;
const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
  }
`;

const ProductInfo = styled.div`
  flex: 0 auto;
  height: 100%;
  width: 90%;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    color: ${colors.darkGrey};
    ${arno(24)}
  }
  div:nth-child(2) {
    ${arno(18)}
    text-decoration: underline;
  }
  div:nth-child(3) {
    ${arno(18)}
  }
`;
const Product = styled(A)`
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    margin-bottom: 50px;
    @keyframes product {
        0%   { opacity: 0; transform: translateY(75px); }
        100% { opacity: 1; }
    }
    animation-timing-function: ease-out;
    animation-fill-mode: forwards
    animation: product .85s;
    &:nth-child(1){
        animation: product .25s;
    }
    &:nth-child(2){
        animation: product .35s;
    }
    &:nth-child(3){
        animation: product .45s;
  4 }
    &:nth-child(4){
        animation: product .55s;
    }
    &:nth-child(5){
        animation: product .65s;
    }
    &:nth-child(6){
        animation: product .75s;
    }

    @media(max-width: 950px){
        min-width: unset;
        max-width: unset;
        flex: 0 auto;
        ${ProductInfo}{
            margin-top: 10px;
        }

    }


`;
const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  img {
    flex: 0 auto;
    object-fit: contain;
    &.tall {
      height: 100%;
      max-height: 210px;
      width: unset;
      @media (max-width: 750px) {
        max-height: 300px;
      }
    }
    &.wide {
      width: 100%;
      height: 100%;
      max-height: 210px;
      max-width: 275px;
    }
  }
  margin-bottom: 20px;
`;
