import React from "react";
import styled from "styled-components";
import { useInView } from "react-hook-inview";
import TipPartial from "./partials/TipPartial";
import { SanityParser } from "./controllers/SanityParser";
import { 
	Container, 
	ContentWrapper, 
	ProductsWrapper, 
	CategoryWrapper, 
	Category
} from "./partials/visuals";

const TipList = ({ 
	linkArray
}) => {
	const [ref, isVisible] = useInView({ unobserveOnEnter: true });
	return (
		<Container
			className={isVisible ? "in-view" : ""}
			ref={ref}
		>
			<ContentWrapper>
				<ProductsWrapper>
					<TipWrapper>
						<Tip>
							{linkArray?.map((link, i) => {
								const parsed = SanityParser({
									_type: link?._type,
									...link,
								});
								return (
									<TipPartial
										parsed={parsed}
										key={i}
									/>
								);
							})}
						</Tip>
					</TipWrapper>
				</ProductsWrapper>
			</ContentWrapper>
		</Container>
	)
}
	
export default TipList;

const TipWrapper = styled(CategoryWrapper)`
	width: 100%;
`;

const Tip = styled(Category)`
	display: flex;
	justify-content: flex-start;
	gap: 25px 50px;
	flex-wrap: wrap;
	@media (max-width: 950px){
		flex-direction: column;
	}
`;