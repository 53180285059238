import { combineReducers } from 'redux';
import pages from './reducers/pageReducer';
import global from './reducers/globalReducer';
import product from './reducers/productReducer';
import category from './reducers/categoryReducer';
import proteinType from './reducers/proteinTypeReducer';
import allergy from './reducers/allergyReducer';
import label from './reducers/labelReducer';
import recipe from './reducers/recipeReducer';
import tip from './reducers/tipReducer';
import article from './reducers/articleReducer';
//REDUCER_IMPORTS

const reducers = combineReducers({
    pages,
    global,
    product,
    category,
	proteinType,
    allergy,
    label,
    recipe,
    tip,
    article
    //REDUCER_COMBINES
});

export default reducers;
