import React from 'react';
import { ImageWrapper, ProductInfo as TipInfo } from './visuals';
import Image from '../../components/image';
import styled from 'styled-components';
import { A } from "hookrouter";

const TipPartial = ({ parsed }) => {
	return(
		<Tip
			href={"/" + parsed.link.url}
		>
			<ImageWrapper>
				<Image
					alt={parsed.title}
					src={parsed.image}
					source={parsed.imageObj}
					width={400}
				/>
			</ImageWrapper>
			<TipInfo>
				<div>{parsed.tagline}</div>
				<div>{parsed.title}</div>
			</TipInfo>
		</Tip>
	);
};

export default TipPartial;

const Tip = styled(A)`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: calc(33% - 50px);
	text-decoration: none;
	${ImageWrapper}{
		flex: 1;
		margin-bottom: 0;
	}
	img{
		max-width: unset;
		flex: 1;
		width: 100%;
		height: auto;
		display: flex;
		object-fit: cover;
	}
	${TipInfo}{
		height: unset;
		margin-top: 16px;
	}
	@media (max-width: 950px){
		max-width: 100%;
	}
`;