import React from "react";
import { useInView } from "react-hook-inview";
import styled from "styled-components";
import { arno, colors } from "../const";

const ImageLinkAndSidebar = ({
  image,
  link,
  text,
  title,
  sidebar,
  backgroundColor,
  anchor,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true });

  return (
    <Container
      backgroundColor={backgroundColor}
      className={"module module-event-list " + (isVisible ? "in-view" : "")}
      ref={ref}
    >
      <ContentWrapper>
        {sidebar ? (
          <Content>
            <h2>{title}</h2>
            <ImageWrapper href={link.url}>
              <img alt={title} src={image} />
            </ImageWrapper>
          </Content>
        ) : (
          <ContentNoBar>
            <h2>{title}</h2>
            <ImageWrapper href={link.url}>
              <img alt={title} src={image} />
            </ImageWrapper>
          </ContentNoBar>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default ImageLinkAndSidebar;

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.brown};` : ""}
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1366px;
  margin-bottom: -55px;
  margin-top: 55px;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  h2 {
    margin-top: 0;
    flex: 30;
    ${arno(42)};
    color: ${colors.darkGrey};
    min-width: 300px;
  }
`;
const ContentNoBar = styled.div`    
    margin-left: 255px;
    margin-right: 255px;
    h2{
        margin-top: 0;
        ${arno(32)}; 
        color: ${colors.darkGrey};
        
    }
    h2,p{
       margin-left: 15px; 
    } 
 }
}
`;

const ImageWrapper = styled.a`
  margin: 0;
  flex: 70;
  align-self: center;
  width: 100%;
  min-width: 300px;
  img {
    min-width: 300px;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;
