import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { SanityParser } from "../modules/controllers/SanityParser";
import useTip from "../hooks/useRecipe";
import IntroImageAndText from "../modules/IntroImageAndText";
import { arno, colors } from "../const";
import { navigate } from "hookrouter";

// From page
import useSlug from '../hooks/useSlug';
import stripSlug from '../utils/stripSlug';
import ModuleList from "../modules/ModuleList";

// From page
function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments)
        }, ms)
    };
}







const SingleTip = ({ Tip, slug }) => {

    useTip(slug.replace("/", ""));
  let parsed = null;
  if (Tip) {
    parsed = SanityParser({
      _type: "Tip",
      ...Tip,
    });
  }

    // From page
    useSlug(decodeURI(slug));
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            });

        },500);
        window.addEventListener('resize', debouncedHandleResize);
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }});

    
  

  if (!Tip) {
    return null;
  }
  if (!parsed) return <div>Loading...</div>;
  return (
    <Container>
        <ModuleList type='page' dimensions={dimensions} modules={Tip.modules} />
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return Object.assign(
    {},
    {
      Tip: state.tip[decodeURI(ownProps.slug)],
    },
    ownProps
  );
};

/* const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            Tip: state.tip[stripSlug(decodeURI(ownProps.slug))]
        },
        ownProps
    );
}; */

export default connect(mapStateToProps, null)(SingleTip);

const Container = styled.div`
    width: 100%;

    &:after {
        content: '';
        clear: both;
        display: table;
    }

    > .module {
        margin-bottom: 80px;
    }
`;
