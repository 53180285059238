import { put, takeEvery, select } from 'redux-saga/effects';
import Api from '../../services/Api';
import stripSlug from "../../utils/stripSlug";

const api = new Api();


function* getAllRecipes() {
    try {
        const {hasAllRecipes} = yield select(state => ({
            recipes: state.recipes,
            hasAllRecipes: state.global.hasAllRecipes
        }));

        if(hasAllRecipes) {
            console.log('Has all recipes in cache');
            return;
        }

        const response = yield api.fetcher('getAllRecipes');

        let allergies = {};
        allergies['recipes'] = {};
        let categories = {};
        categories['recipes'] = {};

        response.recipes.forEach((recipe) => {
            if(recipe.allergies) {
                recipe.allergies.forEach((allergy) => {
                    allergies['recipes'][allergy.title] = allergy.title;
                });
            }
            categories['recipes'][recipe.category.slug.current] = recipe.category;
        });

        yield put({
            type: 'category/set',
            payload: categories
        });
        yield put({
            type: 'allergy/set',
            payload: allergies
        });
        yield put({
            type: 'recipes/set',
            payload: response.recipes
        });
        yield put({
           type: 'global/update',
           payload: response.global
        });
        yield put({
            type: 'global/update',
            payload: {hasAllRecipes: true}
        });
    } catch (e) {
        yield put({
            type: 'site/get-recipes/failed'
        });
    }
}

function* getRecipe(action) {
    try {
        const slug = stripSlug(action.payload);

        // Check if we have the product cached in redux
        let recipe = yield select(state => state.recipe && state.recipe[slug]);

        if (recipe) {
            yield put({
                type: 'recipes/found-in-cache',
                recipe
            });
            return;
        }
        const response = yield api.fetcher('getRecipeBySlug', slug);

        if(response){
            yield put({
                type: 'recipes/set',
                payload: response.recipes
            });
        }

        yield put({
            type: 'global/update',
            payload: response.global
        });
    } catch (e) {
        yield put({
            type: 'page/not-found'
        });
    }
}

export default [
    takeEvery('recipes/all', getAllRecipes),
    takeEvery('recipes/get-by-slug', getRecipe)
];
