import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import useScroll from '../../hooks/useScroll';


const RefWrapper = React.forwardRef((props, ref) => (
    <header ref={ref}>
        <Header {...props} />
    </header>
));

let headerHeight = null;

const MasterLayout = ({ children }) => {
    const { y, direction } = useScroll();
    const header = useRef(null);

    useEffect(() => {
        //as of release 95 is the current value of the header, so we're using it as a failsafe.
        headerHeight = header.current.scrollheight || 125
    }, []);

    let animateFadeOut = y > 125;


    return (
        <Container>
            <RefWrapper
                ref={header}
                animateFadeOut={animateFadeOut}
                direction={direction}
            />
            <PageContent>{children}</PageContent>
            <Footer />
        </Container>
    );
};

export default MasterLayout;


const Container = styled.div`    
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
`;

const PageContent = styled.div`
    display: flex;
    flex: 1;    
    min-height: calc(100vh - 100px);
    padding-top: 120px;
    @media(max-width: 1050px){
        padding-top: 50px;
    }    
`;
