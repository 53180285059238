const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case "articles/create":
            return { ...payload };
        case "articles/set":
            let data = [];
            action.payload.forEach(article => {
                if (article.slug) {
                    // add searchable field to article object
                    let searchableArray = [];
                    let image = false;
                    if(article.modules[0] && article.modules[0].image){
                           image =  article.modules[0].image
                    }

                    article.modules.forEach(module => {
                        //if the module contains a text object
                        if (module.text)
                            module.text.forEach(textNode => {
                                //if the module contains a children object
                                if (textNode.children)
                                    searchableArray.push(
                                        textNode.children[0].text
                                    );
                            });
                        if (module.title) searchableArray.push(module.title);

                    });
                    // extend the articleobject with the searchable string
                    article = {
                        ...article,
                        searchable: searchableArray.join(" "),
                        image: image
                    };
                    //add the object to the array with its slug as the key
                    data[article.slug.current] = article;
                }
            });
            return { ...state, ...data };
        case "articles/update":
            return { ...state, ...payload };
        case "articles/clear":
        case "REDUX/CLEAR":
            return { ...defaultState };
        default:
            return state;
    }
};
