import BlockContent from "@sanity/block-content-to-react";
import React, { useEffect, useMemo, useState } from "react";
import { useInView } from "react-hook-inview";
import connect from "react-redux/es/connect/connect";
import { dispatch } from "../redux";
import stripSlug from "../utils/stripSlug";
import ProteinTypes from "./ProteinTypes.js";
import { SanityParser } from "./controllers/SanityParser";
import FilterDropdown from "./partials/FilterDropdown";
import ProductPartial from "./partials/ProductPartial";
import {
  Category,
  CategoryWrapper,
  Container,
  ContentWrapper,
  ProductsWrapper,
} from "./partials/visuals";

const Search = ({
  title: categoryTitle,
  type,
  allergies = {},
  proteinTypeState,
  productState,
  backgroundColor,
  categories,
  labels,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true });
  const [categoryFilters, setCategoryFilters] = useState([]);
  const [allergyFilters, setAllergyFilter] = useState([]);
  const [proteinTypeFilters, setProteinTypeFilter] = useState([]);
  const [mergedFilters, setMergedFilters] = useState([]);
  const [categoriesToShow, setCategoriesToShow] = useState(
    categories?.map(({ title }) => title)
  );

  useEffect(() => {
    switch (type) {
      case "product":
        dispatch("products/all");
        break;
      case "recipe":
        dispatch("recipes/all");
        break;
    }
  }, []);
  useEffect(() => {
    setMergedFilters(
      mergeFilterTypes(proteinTypeFilters, allergyFilters, categoryFilters)
    );
  }, [proteinTypeFilters, allergyFilters, categoryFilters]);

  const mergeFilterTypes = (protein, allergy, category) => {
    return [...protein, ...allergy, ...category];
  };

  const filteredProducts = useMemo(() => {
    //parse the products so we have all of the proper values present before we start working
    const parsedArray = Object.values(productState).map((product) => {
      return SanityParser({
        _type: type,
        ...product,
      });
    });
    const filtered = parsedArray.filter((parsed) => {
      const categoryMatch =
        categoryFilters.length > 0
          ? categoryFilters.includes(parsed.category)
          : true;
      const allergyFilterables = parsed.allergies.map(
        (allergy) => allergy.title
      );
      const proteinTypeFilterables =
        parsed.proteinTypes?.map((proteinType) => proteinType.title) || [];
      const allergyMatch =
        allergyFilters.length > 0
          ? !allergyFilterables.some((allergy) =>
              allergyFilters.includes(allergy)
            )
          : true;
      const proteinTypeMatch =
        proteinTypeFilters.length > 0
          ? proteinTypeFilterables.some((proteinType) =>
              proteinTypeFilters.includes(proteinType)
            )
          : true;
      return categoryMatch && allergyMatch && proteinTypeMatch;
    });
    const uniqueFilteredCategories = [
      ...new Set(filtered.map(({ category }) => category)),
    ];
    setCategoriesToShow(uniqueFilteredCategories);
    return filtered;
  }, [productState, mergedFilters]);

  return (
    <Container
      backgroundColor={backgroundColor}
      className={"module module-event-list " + (isVisible ? "in-view" : "")}
      ref={ref}
    >
      <ContentWrapper>
        <ProteinTypes
          proteinTypes={proteinTypeState?.products}
          proteinTypeFilters={proteinTypeFilters}
          setProteinTypeFilter={setProteinTypeFilter}
        />
        {/* 		<SeriesFilter
			title={categoryTitle}
			categories={categories}
			categoryFilters={categoryFilters}
			setCategoryFilters={setCategoryFilters}
		/> */}
        <FilterDropdown
          allergies={allergies}
          allergyFilters={allergyFilters}
          setAllergyFilter={setAllergyFilter}
        />
        {categories &&
          categories.map((category, i) => {
            if (!categoriesToShow.includes(category.title)) return null;
            return (
              <CategoryWrapper type={type} key={i}>
                <Category id={category.slug.current}>
                  <h2>{category.title}</h2>
                  {category.text && <BlockContent blocks={category.text} />}
                  <ProductsWrapper>
                    {filteredProducts &&
                      filteredProducts.map((parsed, i) => {
                        if (category.title !== parsed.category) return null;
                        return (
                          <ProductPartial parsed={parsed} type={type} key={i} />
                        );
                      })}
                  </ProductsWrapper>
                </Category>
              </CategoryWrapper>
            );
          })}
      </ContentWrapper>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  switch (ownProps.type) {
    case "product":
      return Object.assign(
        {},
        {
          content: state.pages[stripSlug(ownProps.slug)],
          allergies: state.allergy["products"],
          productState: state.product,
          proteinTypeState: state.proteinType,
        },
        ownProps
      );
    case "recipe":
      return Object.assign(
        {},
        {
          content: state.pages[stripSlug(ownProps.slug)],
          categoryState: state.category["recipes"],
          productState: state.recipe,
        },
        ownProps
      );
  }
};
export default connect(mapStateToProps, null)(Search);
