import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { SanityParser } from "../../modules/controllers/SanityParser";
import { colors, arno } from "../../const";
import { A } from "hookrouter";
import Search from "../../modules/Search";
import SearchIcon from "../svg/icons/SearchIcon";
import Image from "../image";

const Header = ({
  slug,
  menuItems,
  image,
  banner_image,
  animateFadeOut,
  direction,
}) => {
  const [menuState, setMenuState] = useState(false);
  const [searchState, setSearchState] = useState(false);

  if (!menuItems) return <div />;

  const parsed = SanityParser({
    _type: "menu",
    menuItems: menuItems,
    image: image,
    banner_image: banner_image,
  });

  return (
    <Container
      className={
        (!menuState && "scrolling-" + direction) +
        (animateFadeOut ? " animate-fadeout " : "") +
        (menuState ? " menu-open" : "")
      }
    >
      <Wrapper>
        <LogoWrapper>
          <Logo
            onClick={() => {
              setMenuState(false);
              setSearchState(false);
            }}
            href={"/"}
          >
            <Image
              alt="logo"
              src={parsed.image}
              source={parsed.imageObj}
              width={150}
              quality={25}
            />
          </Logo>
        </LogoWrapper>

        <Menu>
          {parsed.links.map((item, index) => {
            return (
              <li key={index}>
                <A
                  onClick={() => {
                    setMenuState(false);
                    setSearchState(false);
                  }}
                  className={slug === item.url ? "this" : ""}
                  href={"/" + item.url}
                >
                  {item.name}
                </A>
              </li>
            );
          })}
          <li className="search-li">
            <SearchIcon onClick={() => setSearchState(!searchState)} />
          </li>
        </Menu>

        <Hamburger
          className={menuState && "open"}
          onClick={() => {
            setMenuState(!menuState);
            setSearchState(false);
          }}
        />
        <MobileWrapper>
          <MobileMenu className={menuState && "open"}>
            <MobileLogoWrapper>
              <MenuLogo
                onClick={() => {
                  setMenuState(false);
                  setSearchState(false);
                }}
                className={menuState && "open"}
                href={"/"}
              >
                <Image
                  alt="logo"
                  src={parsed.image}
                  source={parsed.imageObj}
                  width={150}
                  quality={25}
                />
              </MenuLogo>
            </MobileLogoWrapper>
            <div>
              <BannerImage>
                <Image
                  alt="logo"
                  src={parsed.banner_image}
                  source={parsed.bannerImageObj}
                  width={500}
                  quality={25}
                />
              </BannerImage>
            </div>
            <ul>
              {parsed.links.map((item, index) => {
                return (
                  <li key={index}>
                    <A
                      onClick={() => {
                        setMenuState(false);
                        setSearchState(false);
                      }}
                      className={slug === item.url ? "this" : ""}
                      href={"/" + item.url}
                    >
                      {item.name}
                    </A>
                  </li>
                );
              })}
              <MobileSearch>
                <Search
                  setSearchState={(val) => {
                    setSearchState(val);
                    setMenuState(false);
                  }}
                />
              </MobileSearch>
            </ul>
          </MobileMenu>
        </MobileWrapper>
      </Wrapper>
      <SearchWrapper action="" className={searchState && "open"}>
        <Search
          setSearchState={(val) => {
            setSearchState(val);
            setMenuState(false);
          }}
        />
      </SearchWrapper>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return Object.assign(
    {},
    {
      image: state.global.image,
      banner_image: state.global.banner_image,
      menuItems: state.global.header.menu,
      slug: state.global.current,
    },
    ownProps
  );
};

export default connect(mapStateToProps, null)(Header);

const SearchWrapper = styled.div`
  position: absolute;
  width: calc(100%);
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  background-color: rgb(229, 227, 222);
  overflow: hidden;
  z-index: 1;
  input {
    margin-left: 150px;
  }
  &:not(.open) {
    visibility: hidden;
  }
`;

const MobileSearch = styled.div`
  width: calc(100vw);
  background-color: rgb(229, 227, 222);
  overflow: hidden;
  z-index: 99999;
  text-align: center;
  input {
    margin-left: 5px;
  }
  button {
    visibility: hidden;
    display: none;
  }
`;
const Container = styled.header`
  ${arno(20)}
  background-color: ${colors.brown};
  width: 100%;
  min-height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  .search-li {
    cursor: pointer;
  }
  @media (max-width: 1050px) {
    min-height: 75px;
  }
  position: fixed;
  &.scrolling-up {
    &.animate-fadeout {
      position: fixed;
      top: 0;
      left: 0;
      transition: top 0.4s ease-out;
    }
  }
  &.scrolling-down {
    &.animate-fadeout {
      position: fixed;
      top: -100%;
      transition: top 0.5s ease-in;
    }
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.brown};
  flex: 0 auto;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-right: 25px;
  }
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const MobileLogoWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  z-index: 50;
`;
const LogoWrapper = styled.div`
  display: flex;
  z-index: 3;
  @media (max-width: 1050px) {
    position: absolute;
    top: 17.5px;
    left: 0;
  }
`;
const MenuLogo = styled(A)`
  max-height: 100px;
  width: 126px;
  img {
    max-width: 90px;
  }
`;
const BannerImage = styled.div`
  width: 400px;
  position: absolute;
  top: 60px;
  left: calc(50% - 220px);
  img {
    width: 400px;
  }
`;
const Logo = styled(A)`
  max-height: 126px;
  z-index: 1;
  position: relative;
  height: 100%;
  width: 126px;
  img {
    position: absolute;
    height: 20vw;
    max-height: 126px;
    transform: translateY(-5%);
    @media (max-width: 1050px) {
      max-height: 60px;
    }
  }
  img:hover {
    play-source: url(./quack.mp3) !important;
    play-duration: 2s;
    play-repeat: no-repeat;
  }
  @media (max-width: 1050px) {
    img {
      position: absolute;
      top: -25px;
    }
    @media (max-width: 750px) {
      margin-left: -25px;
    }
  }
`;
const Menu = styled.nav`
  flex: 1 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-right: 55px;
  li {
    list-style: none;
    flex: 0;
    margin-left: 55px;
  }
  a {
    flex: 0;
    white-space: nowrap;
    text-align: right;
    text-decoration: none;
    color: ${colors.darkGrey};
    &.this,
    &:hover {
      color: ${colors.red};
    }
  }
  @media (max-width: 1050px) {
    opacity: 0;
    pointer-events: none;
    display: none;
  }
`;
const MobileWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  pointer-events: none;
`;
const MobileMenu = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100%);
  height: 100vh;
  flex-direction: column;
  background-color: ${colors.brown};
  align-items: center;
  justify-content: flex-start;
  padding-top: 25px;
  ${MobileLogoWrapper} {
    flex: 0 auto;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 50px;
    opacity: 0;
  }
  ul {
    flex: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  li {
    margin-bottom: 5px;
    ${arno(42)}
    margin-left: 0;
    list-style: none;
    flex: 0;
    margin-bottom: 15px;
    text-align: center;
    position: relative;
    opacity: 0;
  }
  a {
    flex: 0;
    white-space: nowrap;
    text-align: right;
    text-decoration: none;
    color: ${colors.darkGrey};
    &.this,
    &:hover {
      color: ${colors.red};
    }
  }
  transition: opacity 0.55s ease-out;
  &.open {
    pointer-events: auto;
    opacity: 1;
    li {
      transition: opacity 1.25s ease-out;
      opacity: 1;
    }
    ${MobileLogoWrapper} {
      transition: opacity 1.25s ease-out;
      opacity: 1;
    }
  }
`;
const Hamburger = styled((props) => (
  <button {...props}>
    <div />
  </button>
))`
  @media (min-width: 1051px) {
    display: none;
  }
  &.open {
    position: fixed;
    top: 15px;
  }
  z-index: 9999;
  width: 40px;
  height: 40px;
  display: inline-flex;
  padding: 10px;
  background: none;
  box-shadow: none;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div {
    position: relative;
    width: 20px;
    height: 3px;
    background-color: black;
    &:before,
    &:after {
      content: " ";
      width: 20px;
      height: 3px;
      display: block;
      background-color: black;
      position: absolute;
    }
    &:after {
      bottom: -6px;
    }
    &:before {
      top: -6px;
    }
  }
  &.open {
    div {
      width: 20px;
      height: 20px;
      background-color: transparent;
      transform: rotate(45deg);
      &:before {
        top: 50%;
        margin-top: -1px;
        left: 0;
        width: 22px;
      }
      &:after {
        width: 3px;
        height: 22px;
        top: 0;
        left: 50%;
        bottom: auto;
        margin-left: -1px;
      }
    }
  }
`;
