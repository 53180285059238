import { navigate, useRoutes } from "hookrouter";
import React, { useEffect } from "react";
import { useAnchorScroll } from "./hooks/useAnchorScroll";
import { usePageTracking } from "./hooks/usePageTracking";
import Page from "./templates/Page";
import SearchPage from "./templates/SearchPage";
import SingleProduct from "./templates/SingleProduct";
import SingleRecipe from "./templates/SingleRecipe";
import SingleTip from "./templates/SingleTip";

const routes = {
  "/": () => <Page slug="/" />,
  "/oppskrifter/:slug": ({ slug }) => <SingleRecipe slug={slug} />,
  "/tips/:slug": ({ slug }) => <SingleTip slug={slug} />,
  "/produkter/:slug": ({ slug }) => <SingleProduct slug={slug} />,
  "/search/:slug": ({ slug }) => <SearchPage slug={slug} />,
  "/:slug*": ({ slug }) => <Page slug={slug} />,
  "*": () => {
    navigate("/", false);
    return <Page slug="/" />;
  },
};

const Routes = () => {
  const hookedRoutes = useRoutes(routes);
  useEffect(() => window.scrollTo(0, 0));
  usePageTracking();
  useAnchorScroll();

  return hookedRoutes || "Not Found";
};

export default Routes;
