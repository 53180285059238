import React from "react";
import { useInView } from "react-hook-inview";
import ReactPlayer from "react-player";
import styled from "styled-components";
import Image from "../components/image";
import { arno, colors as color, colors } from "../const";
import { LinkButton } from "./partials/LinkButton";

const BackgroundImageAndVideo = ({
  dimensions,
  media_image,
  image,
  imageObj,
  link,
  text,
  title,
  video,
  anchor,
}) => {
  const [ref, isVisible] = useInView({ unobserveOnEnter: true });
  const isMobile = dimensions.width < 950;

  return (
    <Container
      className={"module module-event-list " + (isVisible ? "in-view" : "")}
      ref={ref}
      id={anchor}
    >
      <ContentWrapper>
        {!isMobile && <BackgroundWrapper image={image} />}
        <Left>
          <h2 dangerouslySetInnerHTML={{ __html: title }} />
          {text}
          {link && !isMobile && LinkButton(link, true)}
        </Left>
        <Right>
          {media_image && (
            <ImageWrapper>
              <Image
                alt="logo"
                src={media_image}
                source={imageObj.asset}
                width={658}
                quality={50}
              />
            </ImageWrapper>
          )}
          {video && video.vimeo && (
            <Video
              url={video.vimeo}
              height={"500px"}
              width={"400px"}
              loop
              muted
              controls={false}
              config={{
                vimeo: {
                  playerOptions: {
                    controls: false,
                    background: true,
                    byline: 0,
                    title: 0,
                    loop: true,
                  },
                  preloading: true,
                },
              }}
            />
          )}
          {isMobile && <BackgroundWrapper image={image} />}
          {isMobile && link && LinkButton(link, true)}
        </Right>
      </ContentWrapper>
    </Container>
  );
};

export default BackgroundImageAndVideo;

const Container = styled.div`
  position: relative;
  background-color: ${colors.brown};
  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 55px;
  flex-wrap: wrap;
  @media (min-width: 951px) {
    padding-top: 55px;
    padding-bottom: 155px;
  }
  @media (max-width: 950px) {
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
  }
`;
const Left = styled.div`
  flex: 1;
  align-self: flex-start;
  margin-top: 45px;
  margin-right: 50px;
  h2 {
    ${arno(60)}
    color: ${color.darkGrey};
    margin: 0 0 20px 0;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
    ${arno(30)}
    color: ${color.lightGrey}
  }
  > a {
    margin-top: 20px;
    padding: 8px 30px 8px 15px;
  }
`;
const Right = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  > a {
    margin-top: 20px;
    padding: 12px 35px 12px 15px;
  }
`;

const Video = styled(ReactPlayer)`
  height: 500px;
`;

const BackgroundWrapper = styled.div`
  ${(props) => props.image && `background-image: url(${props.image});`}
  background-repeat: no-repeat;
  background-position: -25% 70%;
  background-size: 80%;
  position: absolute;

  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  @media (max-width: 950px) {
    top: unset;
    bottom: 1vw;
    height: 250px;
    width: 100%;
    background-size: 100%;
    background-position: left;
    display: none;
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;

  img {
    width: 100%;
  }

  @media (max-width: 950px) {
    margin-top: 25px;
    align-items: flex-start;
    justify-content: flex-start;
    img {
      height: auto;
      width: 100%;
      max-width: 600px;
      min-width: 200px;
    }
  }
`;
