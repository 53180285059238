import { takeEvery, put } from 'redux-saga/effects';
import Api from '../../services/Api';

const api = new Api();

function* init() {
    try {
        // Fetch all the events
        const response = yield api.getEvents();

        const all = yield api.getAll();


        yield put({
            type: 'events/set',
            payload: response
        });
    } catch (e) {
        console.log('ERROR', e);
    }
}

function* getGlobals() {
    try {
        const response = yield api.getSiteSettings();

        yield put({
            type: 'global/set',
            payload: response
        });
    } catch (e) {
        yield put({
            type: 'site/get-globals/failed'
        });
    }
}

export default [
    takeEvery('site/init', init),
    takeEvery('site/init', getGlobals)
];
