const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'page/create':
            return { ...payload };
        case 'page/set' :
            return { ...state, [action.payload.slug]: action.payload.data };
        case 'page/all':

            let data = [];

            payload.forEach(page => {
                if (page.slug) {
                    let searchableArray = [];
                    let image = false;

                    page.modules.forEach(module => {
                        //if the module contains a text object

                        if(module && module.image && image === false){
                            image =  module.image
                        }
                        if (module.text)
                            module.text.forEach(textNode => {
                                //if the module contains a children object
                                if (textNode.children)
                                    searchableArray.push(
                                        textNode.children[0].text
                                    );
                            });
                        if (module.title) searchableArray.push(module.title);

                    });
                    page = {
                        ...page,
                        searchable: searchableArray.join(" "),
                        image: image
                    };
                    //add the object to the array with its slug as the key
                    data[page.slug.current] = page;
                }
                return { ...state, [action.payload.slug]: action.payload.data };
            });
            return { ...state, ...data };
        case 'page/update':
            return { ...state, ...payload };
        case 'page/clear':
        case 'REDUX/CLEAR':
            return { ...defaultState };
        default:
            return state;
    }
};
