import { usePath } from "hookrouter";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function usePageTracking() {
  const path = usePath();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: path });
  }, [path]);
}

export { usePageTracking };
