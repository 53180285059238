import React, { useState } from 'react';
import styled from 'styled-components';
import { Image } from "./partials/Image";
import { SanityParser } from './controllers/SanityParser';
import { useInView } from 'react-hook-inview';
import { colors, arno } from '../const';

const ProteinTypes = ({ proteinTypes, proteinTypeFilters, setProteinTypeFilter }) => {
	const [ref, isVisible] = useInView({ unobserveOnEnter: true });
	const toggleProteinType = (title) => {
		setProteinTypeFilter(
			proteinTypeFilters.includes(title) ?
				[] :
				[proteinTypeFilters, title]
		);
	}
	if(!proteinTypes) return null;

	return(
		<Container
			className={"module module-event-list " + (isVisible ? "in-view" : "")}
			ref={ref}
		>
			<ProteinTypesWrapper>
				{Object.values(proteinTypes).map(({ image, invertedImage, title }, i) => {
					const isActive = proteinTypeFilters.includes(title);
					const parsed = SanityParser({
						_type: "proteinType",
                        image, 
                        invertedImage,
					});
					return (
						<ProteinType 
							isActive={isActive}
							key={i}
							onClick={() => toggleProteinType(title)}
						>
							<ImageWrapper>
                                {parsed && 
                                    <>
                                        {(isActive && parsed.invertedImage) ?
                                            <Image
                                                alt={title}
                                                src={parsed.invertedImage}
                                                source={parsed.invertedImageObj}                                                
                                                width={100}
                                            />
                                        :
                                            <Image
                                                alt={title}
                                                src={parsed.image}
                                                source={parsed.imageObj}
                                                width={100}
                                            />
                                        }
                                    </>
                                }
							</ImageWrapper>
							<ProteinTypeInfo>
								{title}
							</ProteinTypeInfo>
						</ProteinType>
					);
				})}
			</ProteinTypesWrapper>
{/* 			<span className="divider" />
 */}		</Container>
	);
}

export default ProteinTypes;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .divider{
	  border-bottom: 1px solid ${colors.red};
	  width: 100%;
	  margin: 23px 0 0;
	  max-width: 1366px;
 }
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%; 
  padding: 0;
  justify-content: center;
  align-items: flex-end;
  img {
    width: 100%;
	height: auto;
    max-width: 150px;
    cursor: pointer;
  }
`;

const ProteinTypesWrapper = styled.div`
  max-width: 1366px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 25px;
  div:last-of-type{
	  border: none;
  }
  @media (max-width: 950px) {
	flex-wrap: wrap;
  }
`;

const ProteinTypeInfo = styled.h3`
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: center;
  align-self: center;
  ${arno(27)}
  color: ${colors.red}
`;

const ProteinType = styled.div`
  ${(props) =>
		props.isActive ? `
		h3{
			text-decoration: underline;
		}` : ""}
  display: flex;
  flex-direction: column;
  flex: 1;
  text-decoration: none;
  justify-content: center;
  border-right: 1px dotted ${colors.lightGrey};
  @media (max-width: 950px) {
	width: 100%;  
	min-width: 33.33%;
    ${ProteinTypeInfo} {
      margin-top: 10px;
    }
  }
`;

