import React from "react";
import styled from "styled-components";
import { arno, colors } from "../../const";

import { connect } from "react-redux";
import { SanityParser } from "../../modules/controllers/SanityParser";

import { A } from "hookrouter";
import Image from "../image";

const Footer = ({ image, footerItem, contactItem }) => {
  if (!footerItem) return <div />;
  const parsed = SanityParser({
    _type: "footer",
    footer: footerItem,
    contact: contactItem,
    image: image,
  });
  return (
    <Container>
      <ContentWrapper>
        <LogoWrapper>
          <Logo href={"/"}>
            <Image
              alt="logo"
              src={parsed.image}
              source={parsed.imageObj}
              width={150}
              quality={25}
            />
          </Logo>
        </LogoWrapper>
        <InfoWrapper>
          <Nav>
            {parsed.nav_left.map((item, index) => {
              return (
                <li key={index}>
                  {item.external ? (
                    <a href={item.url}>{item.name}</a>
                  ) : (
                    <A href={"/" + item.url}>{item.name}</A>
                  )}
                </li>
              );
            })}
          </Nav>
          <Nav>
            {parsed.nav_right.map((item, index) => {
              return (
                <li key={index}>
                  {item.external ? (
                    <a href={item.url} target={"_blank"}>
                      {item.name}
                    </a>
                  ) : (
                    <A href={"/" + item.url}>{item.name}</A>
                  )}
                </li>
              );
            })}
          </Nav>

          <ContactWrapper>
            <h3>Kontakt</h3>
            <div>Telefon: {parsed.phone}</div>
            <a href={"mailto:" + parsed.email} target={"_blank"}>
              {parsed.email}
            </a>
            <pre>{parsed.address}</pre>
          </ContactWrapper>
        </InfoWrapper>
      </ContentWrapper>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => {
  return Object.assign(
    {},
    {
      image: state.global.image,
      footerItem: state.global.footer,
      contactItem: state.global.contact,
    },
    ownProps
  );
};

export default connect(mapStateToProps, null)(Footer);

const Container = styled.div`
  display: flex;
  justify-content: center;
  min-height: 304px;
  background-color: ${colors.brown};
  width: 100%;
`;
const ContentWrapper = styled.div`
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  margin-top: 70px;
  width: 100%;
  display: flex;
  align-items: flex-start;
`;
const LogoWrapper = styled.div`
  flex: 3;
`;
const Logo = styled(A)`
  img {
    width: auto;
    max-height: 126px;
    height: 12vw;
  }
  img:hover {
    transform: scale(1.005);
  }
`;

const InfoWrapper = styled.div`
  flex: 7;
  display: flex;
  flex-wrap: wrap;
`;
const Nav = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  li {
    list-style: none;
    margin-bottom: 5px;
    margin-right: 15px;
    a {
      ${arno(18)}
      color: ${colors.darkGrey};
      text-decoration: none;
      &:hover {
        color: ${colors.red};
      }
    }
  }
  margin-bottom: 50px;
`;
const ContactWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  a,
  h3,
  div,
  pre {
    margin: 0;
    color: ${colors.darkGrey};
  }
  h3 {
    ${arno(30)}
    margin-bottom: 20px;
  }
  a,
  pre,
  div {
    ${arno(20)}
  }
  pre {
    margin-top: 20px;
  }
  margin-bottom: 50px;
  @media (max-width: 950px) {
    width: 100%;
    flex: 0 auto;
  }
`;
