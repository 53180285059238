const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'allergy/set':
            return { ...state, ...payload };
        case 'allergy/update':
            return { ...state, ...payload };
        case 'allergy/clear':
        case 'REDUX/CLEAR':
            return { ...defaultState };
        default:
            return state;
    }
};
