const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'proteinType/set':
            return { ...state, ...payload };
        case 'proteinType/update':
            return { ...state, ...payload };
        case 'proteinType/clear':
        case 'REDUX/CLEAR':
            return { ...defaultState };
        default:
            return state;
    }
};
