import React from "react";
import styled from "styled-components";

const SearchIcon = styled(props => {
    return (
        <svg
            onClick={props.onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="23.079"
            height="23.078"
            viewBox="0 0 23.079 23.078"
        >
            <path
                id="Search"
                d="M21.716,23.078a1.37,1.37,0,0,1-.987-.422l-6.313-6.316-.006,0A8.857,8.857,0,0,1,9.1,18.095H9.067A9.083,9.083,0,0,1,2.62,2.629,9.027,9.027,0,0,1,8.962,0H9a9.12,9.12,0,0,1,9.067,9.08,8.879,8.879,0,0,1-1.731,5.328l6.319,6.32a1.363,1.363,0,0,1-.939,2.349ZM8.974,1.81A7.213,7.213,0,0,0,3.941,14.144a7.253,7.253,0,0,0,5.12,2.141h.028A7.238,7.238,0,0,0,9.007,1.81Z"
                transform="translate(0 0)"
                fill="#8e8e93"
            />
        </svg>
    );
})``;

export default SearchIcon;
