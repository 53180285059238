import React from "react";
import imageBuilder from "../utils/imageBuilder";

const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840];

function getSrc({ source, quality, width }) {
  return imageBuilder(source)
    .quality(quality)
    .format("webp")
    .width(width)
    .url()
    .toString();
}

function getSrcSet({ source, quality }) {
  return deviceSizes
    .map((deviceSize) => {
      const url = imageBuilder(source)
        .quality(quality)
        .width(deviceSize)
        .format("webp")
        .url();
      return `${url} ${deviceSize}w`;
    })
    .join(", ");
}

export default function Image({
  quality = 50,
  loading = "eager",
  source,
  width,
  src,
  ...rest
}) {
  return (
    <img
      {...rest}
      loading={loading}
      src={source ? getSrc({ source, quality, width }) : src}
      srcSet={source ? getSrcSet({ source, quality }) : undefined}
    />
  );
}
