import client from '../utils/sanityClient';

export default class Api {

    fetcher = (type, slug = null) => {
        //TODO Check if we need globals
        const getGlobals = true;
        var query = `{`;
        switch (type) {
            case 'getAllProducts' :
                query += this.getAllProducts;
                break;
            case 'getAllRecipes' :
                query += this.getAllRecipes;
                break;
            case 'getAllTips' :
                query += this.getAllTips;
                break;
            case "getAllArticles":
                query += this.getAllArticles;
                break;
            case 'getAllPages' :
                query += this.getAllPages;
                break;
            case 'getProductBySlug' :
                query += this.getProductBySlug((slug));
                break;
            case 'getRecipeBySlug' :
                query += this.getRecipeBySlug((slug));
                break;
            case 'getTipBySlug' :
                query += this.getTipBySlug((slug));
                break;
            default :
                console.log("fetcher not found : ", type);
                return;
        }
        if (getGlobals) query += this.globals;

        return client.fetch(query + '}');
    };

    getProductBySlug = (slug) => `"products": *[_type == 'product' && slug.current == '${slug}' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
    ...,
    category->,
    allergies[]->{
       title
    },
    labels[]->{
        title
    },
	proteinTypes[]->{
		title
	},
    productGrid{
        ...,
        products[]-> {
            ...,
            category->
        },
    },

    }`;

    getRecipeBySlug = (slug) => `"recipes": *[_type == 'recipe' && slug.current == '${slug}' && site == "${process.env.REACT_APP_SITE_NAME}"]{...,}`;

    getTipBySlug = (slug) => `"tips": *[_type == 'tip' && slug.current == '${slug}' && site == "${process.env.REACT_APP_SITE_NAME}"]{...,}`;

    getAllArticles = `"articles": *[_type == 'article, page' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
        ...
    } | order(title asc)`;
    
	modules = `
          
          ...,
          modules[]{
            ...,
            link[]{
                ...,
                internalLink->
            },
             _type == 'productGrid' => {
                ...,
                products[]-> {
                    ...,
                    category->
                },
            },
            _type == 'peopleGrid' => {
                ...,
                people[]->
            },
			_type == 'proteinTypeFilter' => {
                ...,
				title,
				description,
                proteinTypes[]->{
					link->,
					...,
				}
            },
             _type == 'richTextAndSidebar' => {          
             text[]{
                ...,
                asset->
            },      
           },
            categories[]->{
                ...,
            },
			_type == 'tipList' =>{
				linkArray[]{
					image,
					internalLink->,
					_type,
					title,
					tagline,
				},
				...,
			}
          }
        
    `;
    getAllPages = `"pages": *[_type == 'page' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
        ...,
         ${this.modules}
    } | order(title asc)`;

    getAllProducts = `"products": *[_type == 'product' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
        ...,
        category->,
		proteinTypes[]->{
			title,
			image,
            invertedImage,
		},
        allergies[]->{
            title,
        },
        labels[]->{
			title,
		},
        productGrid{
        ...,
        products[]-> {
            ...,
			proteinTypes[]->{
				title
			},
            category->
        },
    },
    } | order(sortindex asc, title asc)` ;

    getAllRecipes = `"recipes": *[_type == 'recipe' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
        ...,
        category->,
        allergies[]->{
            title,
        }
    } | order(title asc)`;

    getAllTips = `"tips": *[_type == 'tip' && site == "${process.env.REACT_APP_SITE_NAME}"][]{
        ...,
        category->,
        ${this.modules}
    } | order(title asc)`;

    globals = `
    ,"global":*[_id=="siteSettings_${process.env.REACT_APP_SITE_NAME}"][0]{
            image,
            banner_image,
            header{
                menu[]{
                    ...,
                    internalLink->
                },
            },
            footer{
                ...,
                nav_left[]{
                   ...,
                    internalLink->{
                       slug,
                       title
                    }
                },
                nav_right[]{
                    ...,
                    internalLink->{
                       slug,
                       title
                    }
                }
            },
            contact{
                ...,
            }
    }
    `;


    //  <!---- fetcher above this line  -->

    getPages = () =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await client.fetch(
                    `*[_type == "page" && site == "${process.env.REACT_APP_SITE_NAME}"]`
                );

            } catch (e) {
                console.log('error', e);
                reject();
            }
        });

    //  getSiteSettings = () => client.fetch(globalsQuery);


    getAll = () =>
        client.fetch(
            `*[_type == 'event' || _type == 'page' || _type == 'venue']`
        );


    getSlug = (slug, getGlobals) => {
        return client.fetch(`{
       "page": *[ site == "${process.env.REACT_APP_SITE_NAME}" && slug.current == '${slug}'][0]{
        ${this.modules}
       },
        ${getGlobals ? `"globals":*[_id=="siteSettings_${process.env.REACT_APP_SITE_NAME}"][0]{
            image,
            ...,
            header{
                menu[]{
                    ...,
                    internalLink->
                },
            },
            footer{
                ...,
                nav_left[]{
                   ...,
                    internalLink->{
                       slug,
                       title
                    }
                },
                nav_right[]{
                    ...,
                    internalLink->{
                       slug,
                       title
                    }
                }
            }
        }` : ''}        
        }
        
    `)
    };


    getSiteSettings = () => {
        return client.fetch(`*[_id=="siteSettings_${process.env.REACT_APP_SITE_NAME}"`)
    };

}


