import { usePath } from "hookrouter";
import { useEffect } from "react";

const useAnchorScroll = () => {
  const path = usePath();

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace("#", "");

      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behaviour: "smooth" });
        }
      }, 2000);
    }
  }, [path]);
};

export { useAnchorScroll };
