import { put, takeEvery, select } from 'redux-saga/effects';
import Api from '../../services/Api';
import stripSlug from "../../utils/stripSlug";

const api = new Api();


function* getAllTips() {
    try {
        const {hasAllTips} = yield select(state => ({
            tips: state.tips,
            hasAllTips: state.global.hasAllTips
        }));

        if(hasAllTips) {
            console.log('Has all tips in cache');
            return;
        }

        const response = yield api.fetcher('getAllTips');

        let categories = {};
        categories['tips'] = {};

        response.tips.forEach((tip) => {
            categories['tips'][tip.category.slug.current] = tip.category;
        });

        yield put({
            type: 'category/set',
            payload: categories
        });
        yield put({
            type: 'tips/set',
            payload: response.tips
        });
        yield put({
           type: 'global/update',
           payload: response.global
        });
        yield put({
            type: 'global/update',
            payload: {hasAllTips: true}
        });
    } catch (e) {
        yield put({
            type: 'site/get-tips/failed'
        });
    }
}

function* getTip(action) {
    try {
        const slug = stripSlug(action.payload);

        // Check if we have the product cached in redux
        let tip = yield select(state => state.tip && state.tip[slug]);

        if (tip) {
            yield put({
                type: 'tips/found-in-cache',
                tip
            });
            return;
        }
        const response = yield api.fetcher('getTipBySlug', slug);

        if(response){
            yield put({
                type: 'tips/set',
                payload: response.tips
            });
        }

        yield put({
            type: 'global/update',
            payload: response.global
        });
    } catch (e) {
        yield put({
            type: 'page/not-found'
        });
    }
}

export default [
    takeEvery('tips/all', getAllTips),
    takeEvery('tips/get-by-slug', getTip)
];
