import styled from "styled-components";


import React, {useState} from "react";
import {arno, colors} from "../const";
import { navigate } from "hookrouter";


const goToSearch = (val) => {
    if(val) navigate("/search/" + val);
};
const Search = ({setSearchState}) => {
    const [searchValue, setSearchValue] = useState('');


    return (
        <Container action=''>
            <Input
                    className="search-query"
                    type="search"
                    value={searchValue}
                    onChange={event => setSearchValue(event.target.value)}
                    placeholder="Tast inn søkeord..."
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            goToSearch(searchValue);
                            setSearchState(false);
                            setSearchValue('');
                        }
                    }}
            />
            <Button onClick={(e) => {
                e.preventDefault();
                goToSearch(searchValue);
                setSearchState(false);
                setSearchValue('');
                }}>
                SØK
            </Button>
        </Container>
    );
};

export default Search;
const Container = styled.form`
    display: flex;
    width: calc(100% - 110px);
    margin: 0 auto;
    justify-content: space-between;
    align-content: center;
    background-color: rgb(229, 227, 222);
    max-width: 1366px;
    padding: 25px 0;
`;
const Button = styled.button`
    flex: auto;
    ${arno(30)};
 
    background-color: ${colors.red};
    color: white;
    padding: 5px 20px 0 20px;
    cursor: pointer;    
    height: 50px;    
    border: unset;
    max-width: 95px;   
    @media(max-width: 750px){
        font-size: 32px;
    }
    
`;

const Input = styled.input`
    all: unset;
    flex: auto;
    color: ${colors.darkGrey};
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style : normal;
    font-size: 22px;
    line-height: 22px;
    @media(min-width: 751px){
        font-size: 42px;
        line-height: 42px;
    }
    &:focus::placeholder {
            color: transparent;
            opacity: 0;
    }   
    &::placeholder {
        font-family: arno-pro, serif;
        font-weight: 400;
        font-style : normal;
        font-size: 22px;
        color: ${colors.darkGrey};        
        @media(min-width: 751px){
            font-size: 42px;
            line-height: 42px;
            height: 42px;
        }
    }
    &::-webkit-search-cancel-button{
        position:relative;
        transform: scale(2);    
        right: 25px;
    }

`;
