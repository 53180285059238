import React, { useState } from "react";
import styled from "styled-components";
import { arno, colors } from "../const";
import { SanityParser } from "./controllers/SanityParser";
import { A } from "hookrouter";
import { useInView } from "react-hook-inview";
import Image from "../components/image";

const ProductGrid = ({ text, title, products, backgroundColor }) => {
  const [gridLimitState, setGridLimitState] = useState(6);
  const [ref, isVisible] = useInView({ unobserveOnEnter: true });

  return (
    <Container
      backgroundColor={backgroundColor}
      className={"module module-event-list " + (isVisible ? "in-view" : "")}
      ref={ref}
    >
      <ContentWrapper>
        <IntroWrapper>
          <h2>{title}</h2>
          <div>{text}</div>
        </IntroWrapper>
        <ProductsWrapper>
          {!products && <div>Loading products...</div>}
          {!!products &&
            Object.keys(products)
              .map((key) => {
                const product = products[key];
                const parsed = SanityParser({
                  _type: "product",
                  ...product,
                });
                return (
                  <Product
                    href={"../produkter/" + parsed.link}
                    key={product._id}
                  >
                    <ImageWrapper>
                      <Image
                        alt={title}
                        src={parsed.image}
                        source={parsed.imageObj}
                        width={300}
                      />
                    </ImageWrapper>
                    <ProductInfo>
                      <div>{parsed.category}</div>
                      <div>{parsed.product_name}</div>
                      <div>{parsed.weight}</div>
                    </ProductInfo>
                  </Product>
                );
              })
              .slice(0, gridLimitState)}
        </ProductsWrapper>
        <Button href={"../produkter"}>SE HELE VÅRT PRODUKTSORTIMENT</Button>
      </ContentWrapper>
    </Container>
  );
};

export default ProductGrid;

const Container = styled.div`
  ${(props) =>
    props.backgroundColor ? `background-color: ${colors.brown};` : ""}
  position: relative;
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
`;
const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  height: 100%;
  max-width: 1366px;
  margin-left: 55px;
  margin-right: 55px;
  @media (max-width: 750px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const IntroWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 70px 0;
  h2,
  p {
    flex: 0;
    text-align: center;
    margin: 0;
  }
  h2 {
    ${arno(30)}
    line-height: 42px;
  }
  p {
    ${arno(20)}
  }
  @media (max-width: 750px) {
    p {
      margin-top: 5px;
    }
  }
`;
const ProductsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
  margin-right: -25px;
  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    margin-right: unset;
  }
`;

export const ProductInfo = styled.div`
  flex: 0 auto;
  height: 100%;
  width: 90%;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    color: ${colors.darkGrey};
    ${arno(24)}
  }
  div:nth-child(2) {
    ${arno(24)}
    text-decoration: underline;
  }
  div:nth-child(3) {
    ${arno(18)}
  }
 @media (max-width: 950px) {
    margin: 0 auto; 
    width: 100%;
  }
`;
const Product = styled(A)`
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  min-width: calc(100% / 3);
  max-width: calc(100% / 3);
  margin-bottom: 50px;
  @media (max-width: 950px) {
    min-width: unset;
    max-width: unset;
    flex: 0 auto;
    ${ProductInfo} {
      margin-top: 10px;
    }
  }
`;
const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 25px;
  padding-left: 0;
  img {
    margin-bottom: 20px;
    flex: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 225px;
    margin: 0 auto;
    margin-left: 0;
  }
  @media (max-width: 950px) {    
    flex: auto;
    align-self: flex-start;
    justify-self: flex-start;
    margin: unset;
    height: unset;
    padding: unset;
    width: 100%;

    img {
      margin: 0 auto;
    }
  }
`;

const Button = styled.a`
  ${arno(18)}
  background-color: ${colors.red}
    color: white;
  padding: 20px 30px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
