export const colors = {
    red: '#7A1B1F',
	brown: '#F3F1EC',
    lightGrey: '#707070',
    darkGrey: '#363636',
	white: '#FFF'
};

export const arno = (size) => {
let arno =
`
    font-family: arno-pro, serif;
    font-weight: 400;
    font-style : normal;
`;
    switch (size) {
		case 16: {
			return (arno + `
            font-size: 16px;
            line-height: 19px;     
            `)
		}
        case 18: {
            return (arno+`
            font-size: 18px;
            line-height: 26px;     
            `)
        }
        case 20: {
            return (arno+`
            font-size: 20px;
            line-height: 28px;      
            `)
        }
        case 22: {
            return (arno+`
            font-size: 22px;
            line-height: 30px;
            @media(max-width: 700px){
                font-size: 4.5vw;
                line-height: 4.5vw;
            }
            `)
        }
        case 24: {
            return (arno+`
            font-size: 24px;
            line-height: 34px;
            @media(max-width: 700px){
                font-size: 5vw;
                line-height: 5vw;
            }
            `)

        }
		case 27: {
			return (arno + `
            font-size: 27px;
            line-height: 62px;
            @media(max-width: 700px){
                font-size: 5vw;
                line-height: 5vw;
            }
            `)

		}
        case 30: {
            return (arno+`
            font-size: 30px;
            line-height: 35px;
            @media(max-width: 700px){
                  font-size: 24px;
                    line-height: 34px;
            }
            `)
        }
        case 32: {
            return (arno+`
            font-size: 32px;
            line-height: 44px;
             @media(max-width: 700px){
                font-size: 5.5vw;
                line-height: 5.5vw;
            }
            `)
        }
		case 38: {
			return (arno + `
            font-size: 38px;
            line-height: 50px;
             @media(max-width: 700px){
                font-size: 5.5vw;
                line-height: 5.5vw;
            }
            `)
		}
        case 42: {
            return (arno+`
            font-size: 42px;
            line-height: 52px;
            @media(max-width: 700px){
                    font-size: 32px;
                    line-height: 44px;
            }
            `)
        }
        case 52: {
            return (arno+`
            font-size: 52px;
            line-height: 54px;
            @media(max-width: 700px){
                font-size: 6.25vw;
                line-height: 6.25vw;
            }
            `)
        }
        case 60: {
            return (arno+`
            font-size: 60px;
            line-height: 64px;
            @media(max-width: 700px){
                font-size: 34px;
                line-height: 40px;
            }
            `)
        }
        case 80: {
            return (arno+`
            font-size: 80px;
            line-height: 91px;
             @media(max-width: 700px){
                font-size: 7.5vw;
                line-height: 7.5vw;
            }
            `)
        }
    }
};

