import React from 'react';
import styled from 'styled-components';

import {SanityParser} from "./controllers/SanityParser";
import BackgroundImageAndVideo from "./BackgroundImageAndVideo";
import ImageAndText from "./ImageAndText";
import VideoAndText from "./VideoAndText";
import ProductGrid from "./ProductGrid";
import IntroImageAndText from "./IntroImageAndText";
import RichTextAndSidebar from "./RichTextAndSidebar";
import ItemFilterOld from "./ItemFilterOld";
import ItemFilter from "./ItemFilter";
import PeopleGrid from "./PeopleGrid";
import ImageLinkAndSidebar from "./ImageLinkAndSidebar";
import Form from "./Form";
import OurProducts from './OurProducts';
import TipList from './TipList';

const ModuleList = ({ modules, dimensions , type}) => {

    if (!modules || !modules.length) return null;
    return (
        <Container className={'container'}>
            {modules.map((module, index) => {
                const parsed = SanityParser(module);
                switch (module._type) {
                    case 'backgroundImageAndVideo':
                        return (
                            <BackgroundImageAndVideo key={index} {...parsed} dimensions={dimensions}/>
                        );
                    case 'imageAndText':
                        return (
                            <ImageAndText key={index} {...parsed} dimensions={dimensions} type={type}/>
                        );
                    case 'videoAndText':
                        return (
                            <VideoAndText key={index} {...parsed} dimensions={dimensions} type={type}/>
                        );
                    case 'introImageAndText':
                        return (
                            <IntroImageAndText key={index} {...parsed} type={type}/>
                        );
                    case 'richTextAndSidebar':
                        return (
                            <RichTextAndSidebar key={index} {...parsed} type={type}/>
                        );
                    case 'productGrid':
                        return (
                            <ProductGrid key={index} {...parsed}/>
                        );
                    case 'productFilter':
                        return (
                            <ItemFilter type={'product'} key={index} {...parsed}/>
                        );
                    case 'peopleGrid':
                        return (
                            <PeopleGrid key={index} {...parsed}/>
                        );
                    case 'recipeFilter' :
                        return (
                            <ItemFilterOld type={'recipe'} key={index} {...parsed}/>
                        );
                    case 'tipList' :
						return (
							<TipList key={index} {...parsed} />
						);
                    case 'imageLinkAndSidebar' :
                        return (
                            <ImageLinkAndSidebar key={index} {...parsed}/>
                        );
                    case 'form' :
                        return (
                            <Form key={index} {...parsed}/>
                        );
					case 'proteinTypeFilter':
						return(
							<OurProducts key={index} {...parsed} />
						);
                }
            })}
        </Container>
    );
};

export default ModuleList;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    >div:nth-child(n+2){
        &{
            transform: translateY(75px);
            transition: transform .5s ease-in;            
        }
                
        &.in-view {
            transform: translateY(0);
        }
        &.no-transition {
            transform: translateY(0);            
            transition: none;
            
        }
    }
`;
