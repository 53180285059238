import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";

export const Video = ({
    url,
    isBackgroundVideo = false,
    props = {}
  }) => {
    const videoWrapperRef = useRef(null);

    const [containerWidth, setContainerWidth] = useState(null);

    useLayoutEffect(() => {
      setContainerWidth(videoWrapperRef.current.offsetWidth);
    }, []);

    return isBackgroundVideo ? (
      <VideoWrapper ref={videoWrapperRef}>
        <StyledReactPlayer
          width={containerWidth}
          height={Math.round(containerWidth / 3 * 2)} // Let's assume aspect ratio will never be above 3:2
          url={url}

          controls={false}
          loop
          muted
          config={{
            vimeo: {
              playerOptions: {
                controls: false,
                background: true,
                byline: 0,
                title: 0,
                loop: true,
              },
              preloading: true,
            },
            youtube: {
              playerVars: { showinfo: 1 }
            }
          }}
          {...props}
        />
      </VideoWrapper>
    ) : (
      <VideoWrapper ref={videoWrapperRef}>
        <StyledReactPlayer
          height={Math.round(containerWidth / 3 * 2)} // Let's assume aspect ratio will never be above 3:2
          width={containerWidth}
          url={url}

          controls={true}
          config={{
            vimeo: {
              playerOptions: {
                controls: true,
              },
              preloading: true,
            },
            youtube: {
              playerVars: { showinfo: 1 }
            }
          }}
          {...props}
        />
      </VideoWrapper>
    )
}

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: calc(100% / 3 * 2); // Let's assume aspect ratio will never be above 3:2
`

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;
