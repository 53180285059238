import React, { useEffect } from 'react';
import { dispatch } from '../redux';

const useProduct = slug =>

    useEffect(() => {
        if(!slug) {
            dispatch('products/all');
        } else {
            dispatch('products/get-by-slug', decodeURI(slug));
        }
    }, [slug]);

export default useProduct;
