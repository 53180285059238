import React from 'react';
import { Product, ImageWrapper, ProductInfo, Label } from './visuals';
import Image from '../../components/image';

const ProductPartial = ({ parsed, type }) => {
	let baseUrl;
	switch (type) {
		case "product":
			baseUrl = "produkter/";
			break;
		case "recipe":
			baseUrl = "oppskrifter/";
			break;
	}

	const renderLabels = (product) => {
		if (product.labels) {
			if (product.labels.length > 0) {
				return (
					<Label>
						{product.labels[0]?.title?.toUpperCase()}
					</Label>
				)
			}
		}
		return null;
	}

	return(
		<Product
			href={"/" + baseUrl + parsed.link}
		>
			<ImageWrapper>
				<Image
					alt={parsed.title}
					src={parsed.image_small}
					source={parsed.imageSmallObj}
					width={400}
				/>
			</ImageWrapper>
			<ProductInfo>
				<div>{parsed.category}{
				parsed?.proteinTypes ? 
				` – ${parsed.proteinTypes.map(({ title }) => ` ${title}`)}` :
				''
			}</div>

				{type === "recipe" && <div>{parsed.title}</div>}
				{type === "product" && (
					<>
						<div>{parsed.title}</div>
						<div>{parsed.weight}</div>
					</>
				)}
			</ProductInfo>
			{renderLabels(parsed)}
		</Product>
	);
};

export default ProductPartial;