import React, { useState } from "react";
import OptimizedImage from "../../components/image";

export const Image = (props) => {
  const [sizes, setSizes] = useState(false);

  return (
    <OptimizedImage
      {...props}
      className={sizes && sizes.width / sizes.height <= 1 ? "tall" : "wide"}
      onLoad={(val) =>
        setSizes({
          width: val.target.naturalWidth,
          height: val.target.naturalHeight,
        })
      }
    ></OptimizedImage>
  );
};
